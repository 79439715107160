import { Modals, ModalType } from "../../../Shared/Components/Modals";
import { Forms } from "../../../Shared/Components/Forms";

class AccountHelp {

    private helpTypeValidationError = "help-type-validation-error";
    initialize() {

        const form = $('#account-help-form');
        form.submit(e => this.onFormSubmit(e));
    }

    private onFormSubmit(e: JQuery.SubmitEvent) {
        const form = $(e.currentTarget);
        this.displayErrorMessageForHelpTypes();
        this.validationCheckBoxForHelpTypes();

        if (form.valid()) {
            e.preventDefault();
            this.ajaxPOST(form);
        }
    }

    private ajaxPOST(form: JQuery<HTMLElement>) {
        const formData = Forms.getJsonData(form);
        const that = this;

        $.ajax({
            type: 'POST',
            data: formData,
            success: function (response) {
                Modals.displayModal({ ...response, Type: ModalType.Success });
            },
            error: function (response) {
                that.handlePostResponse(response);
            },
        });
    }
    private handlePostResponse(response: JQuery.jqXHR) {
        const data = response.responseJSON;
        if (data.message = this.helpTypeValidationError) {
            this.displayErrorMessageForHelpTypes();
            this.validationCheckBoxForHelpTypes();
        }

        else {
            Modals.displayModal({ ...data, Type: ModalType.Error });
        }
    }
    private displayErrorMessageForHelpTypes() {
        var helpTypesCheckBoxCheckedCount = $("input:checked").length;
        if (helpTypesCheckBoxCheckedCount < 1) {
            $('#helpTypes-error').html('One or more checkboxes are required.');
            $('#helpTypes-error').addClass("field-validation-error");
        }
    }
    private validationCheckBoxForHelpTypes() {
        $('input[type="checkbox"]').click(function () {
            var helpTypesCheckBoxCheckedCount = $("input:checked").length;
            var valid = helpTypesCheckBoxCheckedCount > 0;
            if (valid) {
                $('#helpTypes-error').hide();
            }
            else if ($(this).is(":not(:checked)")) {
                $('#helpTypes-error').show();
            }
        });
    }
}

$(document).ready(() => {
    const page = $("#account-help-page");
    if (!page?.length) {
        return;
    }

    const pageModel = new AccountHelp();
    pageModel.initialize();
});