import { Modals, ModalType } from "../../Shared/Components/Modals";
import { Forms } from "../../Shared/Components/Forms";

class ForgotUsername {

    initialize() {
        const form = $('#forgot-username-page form');
        form.submit(e => this.onFormSubmit(e));
    }

    private onFormSubmit(e: JQuery.SubmitEvent) {
        const form = $(e.currentTarget);

        if (form.valid()) {
            e.preventDefault();
            this.ajaxPOST(form);
        }
    }

    private ajaxPOST(form: JQuery<HTMLElement>) {
        const formData = Forms.getJsonData(form);
        const that = this;

        $.ajax({
            type: 'POST',
            data: formData,
            success: function (data) {
                Modals.displayModal({ ...data, Type: ModalType.Success });
            },
            error: function (response) {
                that.handlePostResponse(response);
            },
        });
    }

    private handlePostResponse(response: JQuery.jqXHR) {
        const data = response.responseJSON;
        Modals.displayModal({ ...data, Type: ModalType.Error, renderType: 'HTML' });
    }
}

$(document).ready(() => {
    const page = $("#forgot-username-page");
    if (!page?.length) {
        return;
    }

    const pageModel = new ForgotUsername();
    pageModel.initialize();
});