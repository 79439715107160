import { Modals, ModalType } from "../../../Shared/Components/Modals";
import { Forms } from "../../../Shared/Components/Forms";

class ForgotPassword {

    private userNameSelector = "UserName";

    initialize() {
        const form = $('#forgot-password-form');
        form.submit(e => this.onFormSubmit(e));
    }

    private onFormSubmit(e: JQuery.SubmitEvent) {
        const form = $(e.currentTarget);       

        if (form.valid()) {
            e.preventDefault();            
            this.ajaxPOST(form);            
        }
    }

    private ajaxPOST(form: JQuery<HTMLElement>) {
        $('#btn-reset').prop('disabled', true);
        const formData = Forms.getJsonData(form);
        const that = this;

        $.ajax({
            type: 'POST',
            data: formData,
            success: function (response) {
                Modals.displayModal({ ...response, Type: ModalType.Success });
                $('#btn-reset').prop('disabled', false);
            },
            error: function (response) {
                that.handlePostResponse(response);
                $('#btn-reset').prop('disabled', false);
            },
        });
    }  

    private handlePostResponse(response: JQuery.jqXHR) {
        const data = response.responseJSON;
        if (response.status === 400) {
             this.setError(this.userNameSelector, data.errorMessage);
        }
        else {
            Modals.displayModal({ ...data, Type: ModalType.Error });
        }
    }

    private setError(elementName: string, message: string) {
        const span = $(`span[data-valmsg-for="${elementName}"]`);
        if (span && span.length > 0) {
            $(span).html(message);
            if (message) {
                $(`input[name="${elementName}"]`).addClass("input-validation-error");
                $(span).removeClass("field-validation-valid");
                $(span).addClass("field-validation-error");
            } else {
                $(`input[name="${elementName}"]`).removeClass("input-validation-error");
                $(span).removeClass("field-validation-error");
                $(span).addClass("field-validation-valid");
            }
        }
    }
}

$(document).ready(() => {
    const page = $("#forgot-password-page");
    if (!page?.length) {
        return;
    }

    const pageModel = new ForgotPassword();
    pageModel.initialize();
});