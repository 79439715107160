export class EmulationPage {
    static onCompanyComboBoxChange(event: kendo.ui.ComboBoxChangeEvent) {
        const userComboBox = $("#UserName").data("kendoComboBox");
        const companyNumber = event.sender.dataItem()?.Id;

        if (userComboBox && companyNumber) {
            userComboBox.select((dataItem: any) => {
                return dataItem.CompanyNumber == companyNumber && dataItem.IsDefault == true;
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const page = $("#emulation-page");
    if (!page?.length) {
        return;
    }
});
