// This gets initialized in the html by the server
declare var verifyError: boolean;

class LoginWith2Fa {
    static callButtonSelector = "#call-button";
    static verifyButtonSelector = "#verify-code-button";
    static verifyCodeElementsSelector = "#verify-code-group";
    static phoneNumberListSelector = "#phone-number-select";
    static verifyCodeInput = "#VerifyCode";
    static textMessageRadioButton = '#text-message';
    static phoneMessageRadioButton = '#phone-message';

    initialize() {
        if (!$("#OrginalCode").val()) {
            this.hideVerifyElements();
        } else {
            this.showVerifyElements();
        }

        const verifyCodeInput = <HTMLInputElement><unknown>$(LoginWith2Fa.verifyCodeInput)[0];
        verifyCodeInput.addEventListener('keyup', this.enableCodeVerifyButton);
        verifyCodeInput.addEventListener('keypress', this.isNumber, true);

        const phoneNumberSelect = <HTMLElement><unknown>$(LoginWith2Fa.phoneNumberListSelector)[0];
        phoneNumberSelect.addEventListener('change', this.onPhoneNumberChange);

        verifyCodeInput.disabled = verifyError;

        const radioText = <any><unknown>$(LoginWith2Fa.textMessageRadioButton)[0];
        radioText.addEventListener('click', this.OnMessageTypeClick);
        const radioPhone = <any><unknown>$(LoginWith2Fa.phoneMessageRadioButton)[0];
        radioPhone.addEventListener('click', this.OnMessageTypeClick);

        const callButton = <HTMLButtonElement><unknown>$(LoginWith2Fa.callButtonSelector)[0];
        callButton.disabled = !radioText.checked && !radioPhone.checked;
    }

    private OnMessageTypeClick() {
        $(LoginWith2Fa.callButtonSelector).prop('disabled', false);
    }

    private isNumber(evt: any) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return evt.preventDefault();
        }
        return true;
    }

    private onPhoneNumberChange() {
        const verifyCodeInput = <HTMLInputElement><unknown>$(LoginWith2Fa.verifyCodeInput)[0];
        verifyCodeInput.value = '';
        verifyCodeInput.disabled = true;
    }
   
    private enableCodeVerifyButton() {
        const verifyCodeInput = <HTMLInputElement><unknown>$(LoginWith2Fa.verifyCodeInput)[0];
        if (verifyCodeInput.value.length > 3) {
            $('#verify-code-button').prop('disabled', false);
        } else {
            $('#verify-code-button').prop('disabled', true);
        }
    }

    private hideVerifyElements() {
        $(LoginWith2Fa.verifyCodeElementsSelector).hide();
        $(LoginWith2Fa.verifyButtonSelector).hide();
    }

    private showVerifyElements() {
        $(LoginWith2Fa.verifyCodeElementsSelector).show();
        $(LoginWith2Fa.verifyButtonSelector).show();
    }
}

$(document).ready(() => {
    const page = $("#TwoFa-login-page");
    if (!page?.length) {
        return;
    }

    const pageModel = new LoginWith2Fa();
    pageModel.initialize();
});