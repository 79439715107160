import { Modals, ModalType } from "../../../Shared/Components/Modals";
import { Forms } from "../../../Shared/Components/Forms";

class ChangePassword {
    private currentPasswordNameSelector = "ChangePassword.CurrentPassword";
    private newPasswordNameSelector = "ChangePassword.NewPassword";
    private currentPasswordIdSelector = 'ChangePassword_CurrentPassword';
    private newPasswordIdSelector = 'ChangePassword_NewPassword';
    private confirmPasswordIdSelector = 'ChangePassword_ConfirmPassword';

    initialize() {
        const form = $('#change-password-page form');
        form.submit(e => this.onFormSubmit(e));

        Forms.enableCapsLockAlert(this.currentPasswordIdSelector, "current-password-caps-lock-alert");
        Forms.enableCapsLockAlert(this.newPasswordIdSelector, "new-password-caps-lock-alert");
        Forms.enableCapsLockAlert(this.confirmPasswordIdSelector, "confirm-password-caps-lock-alert");

        this.attachTogglePasswordVisibilityEvent();
    }

    private attachTogglePasswordVisibilityEvent() {
        $(".toggle-password-value").click(function (event) {
            var inputField = $('#ChangePassword_' + event.target.id);
            $('#' + event.target.id).toggleClass("fa-eye fa-eye-slash");
            if (inputField.attr("type") == "password") {
                inputField.attr("type", "text");
            } else {
                inputField.attr("type", "password");
            }
        });
    }

    private onFormSubmit(e: JQuery.SubmitEvent) {
        const form = $(e.currentTarget);

        if (form.valid()) {
            e.preventDefault();
            this.ajaxPOST(form);
        }
    }

    private ajaxPOST(form: JQuery<HTMLElement>) {
        const formData = Forms.getJsonData(form);
        const that = this;
        let redirectUrl = "/Account/Login?neiSite=" + formData.NEISite;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paramUrl = urlParams.get('ReturnUrl');
        if (paramUrl) {
            redirectUrl = paramUrl;
        }
        $.ajax({
            type: 'POST',
            data: formData,
            success: function (data) {
                Modals.displayModal({ ...data, Type: ModalType.Success, redirectUrl: redirectUrl });
            },
            error: function (response) {
                that.handlePostResponse(response);
            },
        });
    }

    private handlePostResponse(response: JQuery.jqXHR) {
        const data = response.responseJSON;
        if (response.status === 400) {
            if (data.errorType === PasswordErrorType.NewPassword) {
                this.setError(this.newPasswordNameSelector, data.errorMessage);
            }
            else {
                this.setError(this.currentPasswordNameSelector, data.errorMessage);
            }
        }
        else {
            Modals.displayModal({ ...data, Type: ModalType.Error });
        }
    }

    private setError(elementName: string, message: string) {
        const span = $(`span[data-valmsg-for="${elementName}"]`);
        if (span && span.length > 0) {
            $(span).html(message);
            if (message) {
                $(`input[name="${elementName}"]`).addClass("input-validation-error");
                $(span).removeClass("field-validation-valid");
                $(span).addClass("field-validation-error");
            } else {
                $(`input[name="${elementName}"]`).removeClass("input-validation-error");
                $(span).removeClass("field-validation-error");
                $(span).addClass("field-validation-valid");
            }
        }
    }
}

enum PasswordErrorType {
    CurrentPassword = "currentPassword",
    NewPassword = "newPassword",
}

$(document).ready(() => {
    const page = $("#change-password-page");
    if (!page?.length) {
        return;
    }

    const pageModel = new ChangePassword();
    pageModel.initialize();
});