// This gets initialized in the HTML by the server.
declare var forgotPasswordLinks: Record<string, string>;

class Login {
    initialize() {

        const authtimeout: number = Number($("#AuthTimeout").val());
        const commonlandingpagurl: string = String($("#CommonLandingPageUrl").val());
        setTimeout(function () {
            try {
                location.replace(commonlandingpagurl);                
            }
            catch (e) {
                window.location.href = commonlandingpagurl;
            }
        }, authtimeout)

        // Removes the display URL parameters to avoid accidental re-uses of the client correlation information.
        history.replaceState(null, document.title, location.pathname);
        
        $(".sso-login").click(function () {
            const link = "SSO?ReturnUrl=" + $("#ReturnUrl").val() + "&neiSite=" + $("#NEISite").val();
            $(".sso-login").attr("href", link);
        });

        $("#forgot-password").click(function () {
            const forgotPasswordLink = "Password/ForgotPassword?ReturnUrl=" + $("#ReturnUrl").val() + "&reset_password_type=regular";
            $("#forgot-password").attr("href", forgotPasswordLink);
        });

        $("#first-login").click(function () {
            const firstTimeLoginLink = "Password/ForgotPassword?ReturnUrl=" + $("#ReturnUrl").val() + "&reset_password_type=firstTime";
            $("#first-login").attr("href", firstTimeLoginLink);
        });

        $("#forgot-username").click(function () {
            const forgotUsernameLink = "ForgotUsername?ReturnUrl=" + $("#ReturnUrl").val() + "&page_origin=forgotUsername";
            $("#forgot-username").attr("href", forgotUsernameLink);
        });
    }
}

$(document).ready(() => {
    const page = $("#login-page");
    if (!page?.length) {
        return;
    }

    const pageModel = new Login();
    pageModel.initialize();
});