import { ModalPopup, Modals, ModalType } from '../Modals';

export class Forms {
    // This is the same regex being used in the email validator server-side.
    // If changing here, also change in Library.Validations
    private static readonly emailValidatorRegex = /^(?!.*\.web$)((?!\.)(?!.*?\.\.)[a-zA-Z0-9_\-.+&"!#$%'*/=?^`{|}~;]*[a-zA-Z0-9_\-+&"!#$%'*/=?^`{|}~;])@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9][a-zA-Z0-9\\-]*\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

    static readonly emailRegExp = new RegExp(Forms.emailValidatorRegex);

    /**
     * This needs to be called at script load, not after the document has been loaded.
     * */
    static registersQueryValidators() {
        // Register the validator for the our custom email-address-list. Specific pages do need to implement the actual code. Check after ComposeMessage/ComposeMessage.ts to know how.
        $.validator.unobtrusive.adapters.addBool("email-address-list");

        /*
         * The below functions will provide client-side "Not Equal To" validation to any properties
         * annotated with the [NotEqualTo] DataAttribute. See EditPassword page for an example.
         */
        $.validator.unobtrusive.adapters.add("notequalto", ["otherproperty"], function (options: { params: { otherproperty: any; }; rules: { [x: string]: { otherproperty: any; }; }; messages: { [x: string]: any; }; message: any; }) {
            const params = {
                otherproperty: options.params.otherproperty,
            };

            options.rules["notequalto"] = params;
            options.messages["notequalto"] = options.message;
        });

        $.validator.addMethod("notequalto", (value: string, _element: HTMLElement, params: any) => {
            const otherProperty = $("[name*='" + params.otherproperty + "']");

            return (otherProperty.val() != value);
        });

        /**
         * Must add this because jQuery validate does not validate hidden fields by default. Kendo "hides"
         * the underlying HTML element when it renders its version its controls.
         * Add more entries here to allow jQuery validation to validate underlying Kendo controls
         */
        $.validator.setDefaults({
            ignore: ":hidden:not(#Message_CCs):not(#Ticket_PhoneNumber)" //ComposeMessage, Support pages
        });
    }

    static initializeForms() {
        /* This is to disable capslock bubble for IE and Edge */
        (document as any).msCapsLockWarningOff = true;

        /* Customizes jQuery Validation to trigger validation after every keypress and to highlight the field labels. */
        const isValid = function (this: any, element: Element) {
            this.element(element);
        };
        const validator = $('form').data('validator');
        if (validator) {
            validator.settings.onfocusout = isValid;
            validator.settings.onkeyup = isValid;
            $.validator.methods.email = function (value: string) {
                return Forms.emailValidatorRegex.test(value);
            };
        }
    }

    /**
     * Helper function to retrieve a JSON object from form input values.
     * @param form A JQuery Element that represents a form.
     */
    static getJsonData(form: JQuery<HTMLElement>): { [key: string]: string | string[]; } {

        return form.serializeArray().reduce((jsonData: { [key: string]: string | string[]; }, formValue) => {
            const currentValue = jsonData[formValue.name];

            if (currentValue) {
                if (typeof currentValue === "string") {
                    jsonData[formValue.name] = [currentValue, formValue.value];
                }
                else {
                    currentValue.push(formValue.value);
                }
            }
            else {
                jsonData[formValue.name] = formValue.value;
            }

            return jsonData;
        }, {});
    }

    /**
     * Helper function to perform an AJAX POST request to the server and display a modal based on its response.
     */
    static ajaxPOSTWithModal(settings: { url?: string; data?: any; formSelector?: string; requestVerificationToken?: any; }) {
        $.ajax({
            url: settings.url,
            type: 'POST',
            data: settings.data || $(settings.formSelector || 'form').serialize(),
            headers: {
                'RequestVerificationToken': settings.requestVerificationToken
            },
            success: function (data: any) {
                const modal = <ModalPopup>{
                    header: data.Header ?? data.header,
                    message: data.Message ?? data.message,
                    redirectUrl: data.RedirectUrl ?? data.redirectUrl,
                    Type: ModalType.Success,
                };
                Modals.displayModal(modal);
            },
            error: function (response) {
                if (response.responseJSON.header == 'XSS-Injection-Validation') {
                    $('#invalid-ticket-details-error-container').text(response.responseJSON.message);
                } else {
                     const data = response.responseJSON as ModalPopup;
                     Modals.displayModal({ ...data, Type: ModalType.Error });
                }
            },
        });
    };

    /* This is to show capslock on alert */
    static enableCapsLockAlert(inputSelector: string, messageSelector: string) {
        const passwordInput = document.getElementById(inputSelector);
        if (passwordInput) {
            passwordInput.addEventListener("keyup", function (event) {
                const passwordText = document.getElementById(messageSelector);
                if (passwordText) {
                    if (event.getModifierState("CapsLock")) {
                        passwordText.style.display = "block";
                    } else {
                        passwordText.style.display = "none";
                    }
                }
            });
        }
    }
}
