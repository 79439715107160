export enum ModalType {
    Success,
    Error,
    BorderlessMessage
}
export interface ModalPopup {
    Type: ModalType;
    header: string;
    message: string;
    redirectUrl?: string;
    renderType?: string;
}

export class Modals {
    static displayModal(modal: ModalPopup) {
        if (modal.Type === ModalType.Success) {
            $("#modalErrorMsg").hide();
            $("#modalBorderlessMsg").hide();
            $('#modalSuccessMsg').show();
            $('#modalSuccessMsg span').text(modal.message);
         }
        else if (modal.Type === ModalType.BorderlessMessage) {
            $("#modalErrorMsg").hide();
            $("#modalBorderlessMsg").show();
            $('#modalSuccessMsg').hide();
            $('#modalBorderlessMsg span').text(modal.message);
        }
        else {
            $("#modalSuccessMsg").hide();
            $("#modalBorderlessMsg").hide();
            $('#modalErrorMsg').show();

            Modals.checkIfModalMessageEmpty(modal);

            if (modal.renderType == 'HTML') {
                $('#modalErrorMsg span').html(modal.message);
            } else {
                $('#modalErrorMsg span').text(modal.message);
            }
        }

        $('#modalTitle').text(modal.header);
        
        if (modal.redirectUrl) {
            $('#btnDismissModalPopup').attr('href', modal.redirectUrl).removeAttr('data-dismiss');
        }
        else {
            $('#btnDismissModalPopup').removeAttr('href').attr('data-dismiss', 'modal');
        }

        $("#modalPopup").modal({ backdrop: 'static' });
    }

    static checkIfModalMessageEmpty(modal: ModalPopup) {

        if (modal.message && modal.message.length > 0) {
            return modal
        } else {

            var returnUrl = $('#ReturnUrl').val();
            var finalReturnUrl = "ReturnUrl="+returnUrl;

            if (!returnUrl) {
                var neiSite = $('#NEISite').val();
                finalReturnUrl = "neiSite="+neiSite;
            } 

            modal.message = "There was an unexpected error.  Please try again or contact <a href = /Account/Password/Help/Index?"+finalReturnUrl+">NEI Support</a>";
            modal.header = "Error";
            modal.renderType = "HTML";
        }
    }
}
