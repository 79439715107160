import './Account/Login';
import './Account/Password/Change/ChangePassword';
import './Account/Password/ForgotPassword/ForgotPassword';
import './Account/ForgotUsername/ForgotUsername';
import './Account/Password/Help/AccountHelp';
import './Account/TwoFA/LoginWith2Fa';
import { EmulationPage } from './Emulation/Emulation';
import { TransfereeSelectMove } from './Account/Transferee/SelectMove/TransfereeSelectMove';

(window as any).EmulationPage = EmulationPage;
(window as any).TransfereeSelectMove = TransfereeSelectMove;

document.addEventListener("DOMContentLoaded", () => {
    $('body').removeClass('d-none');
});