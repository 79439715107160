export class TransfereeSelectMove {
    initialize() {
        $("#continue-button").attr("disabled", "disabled");

        (kendo as any).syncReady(() => {
            this.setMobileMode();
        });
    }

    static onGridChange(event: kendo.ui.GridChangeEvent) {
        $("#continue-button").removeAttr("disabled");

        const grid = event.sender;
        const dataItem: any = grid.dataItem(grid.select());

        if (dataItem) {
            $("#tid").val(dataItem.Tid);
        }
    }

    private setMobileMode() {
        const isMobile = Boolean(kendo.support.mobileOS);
        if (isMobile) {
            const kendoGrid = this.getKendoGrid("TransfereeMovesGrid");

            if (kendoGrid) {
                kendoGrid.setOptions({
                    height: "50vh",
                    width: kendoGrid.element.width(),
                    scrollable: true
                });
            }
        }
    }

    private getKendoGrid(selector: string) {
        return $("#" + selector).data("kendoGrid");
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const page = $("#transferee-select-move-page");
    if (!page?.length) {
        return;
    }

    const pageModel = new TransfereeSelectMove();
    pageModel.initialize();
});
